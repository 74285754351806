interface RetryOptions {
  /**
   * Number of retries.
   *
   * Total number of action calls will be `times` + 1.
   */
  times: number;
  /**
   * Delay of first rety.
   *
   * Subsequent retries are delayed by double the last delay time.
   */
  delay: number;
}

export async function retry<T>({ times, delay }: RetryOptions, action: () => Promise<T>): Promise<T> {
  for (;;) {
    try {
      return await action();
    } catch (e) {
      if (--times < 0) {
        throw e;
      }
      const currentTimeout = delay;
      await new Promise((resolve) => setTimeout(resolve, currentTimeout));
      delay *= 2;
    }
  }
}
