import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import {
  extractIntegrationData,
  extractRestrictedPageParams,
  parseAppSectionParams,
} from '@wix/pricing-plans-router-utils';
import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import { createGroupsApi } from '../../services';
import { Analytics } from '../../services/analytics';
import { WarmupData } from '../../services/WarmupData';
import type { CommonProps } from '../../types/common';
import { hasMultiplePages } from '../../utils/multiple-pages';
import { Navigation } from '../PackagePicker/controller/Navigation';
import { RestrictedController } from '../PackagePicker/controller/RestrictedController';
import { Router } from '../PackagePicker/controller/Router';

export const createController: CreateControllerFn = async ({ flowAPI }: ControllerParams) => {
  const { setProps: untypedSetProps, wixCodeApi, appParams, compId } = flowAPI.controllerConfig;

  const setProps: (props: Partial<{} & CommonProps>) => void = untypedSetProps;

  const isMultiPage =
    flowAPI.experiments.enabled(TPA_EXPERIMENTS.INSTALL_SPLIT_PAGES) && (await hasMultiplePages(wixCodeApi));

  const groupsApi = createGroupsApi(flowAPI.httpClient);
  const analytics = new Analytics(wixCodeApi.window);
  const nav = new Navigation(flowAPI, appParams.appDefinitionId, isMultiPage);
  const router = new Router(setProps, nav, analytics, wixCodeApi, flowAPI);
  const warmupData = new WarmupData(compId, wixCodeApi, flowAPI);
  const restricted = new RestrictedController(setProps, wixCodeApi, router, groupsApi, flowAPI, warmupData);

  return {
    async pageReady() {
      setProps({ fitToContentHeight: true });

      const params = parseAppSectionParams(wixCodeApi.location.query?.appSectionParams);
      const restrictedPageParams = extractRestrictedPageParams(params);
      const integrationData = extractIntegrationData(params);

      // Differently from other components we call initialize method here, because Paywall component is not connected to
      // router and does all the data loading itself.
      await restricted.initialize(restrictedPageParams ?? { restrictedPageId: '' }, integrationData);
    },
    updateConfig($w, updatedConfig) {},
  };
};
